<script>
  import { InputPhone, Button, Grid } from 'pik-arenda-ui-kit';
  import { SentryHelper, SingleSignHelper } from 'pik-arenda-ui-kit/core';
  import { stepStore, phoneStore } from '../../stores';
  import { STEP_CONFIRM } from '../../constants';

  export let mode;

  mode = 'default';

  let input;
  let error = undefined;
  let valid = undefined;
  let loading = false;
  let windowWidth;

  async function onSubmit(e) {
    e.preventDefault();

    valid = input.validate();
    if (valid) {
      try {
        loading = true;
        const value = input.get();
        const requestConfig = { params: { silent: true } };
        await SingleSignHelper.login(value, requestConfig);
        loading = false;

        phoneStore.set(value);
        stepStore.set(STEP_CONFIRM);
      } catch (exception) {
        SentryHelper.capture(exception);

        loading = false;
        error = 'Неизвестная ошибка';
        // eslint-disable-next-line require-atomic-updates
        valid = false;
      }
    }
  }
</script>

<style src="./styles.pcss" lang="pcss">.component{margin-top:16px}.field :global(label){margin-bottom:9px!important}.controls{margin-top:9px}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9mZWF0dXJlcy9GbGF0Qm9va2luZ0ZlYXR1cmUvc3RlcHMvRmxhdEJvb2tpbmdQaG9uZVN0ZXAvRmxhdEJvb2tpbmdQaG9uZVN0ZXAuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLFdBQ0UsZUFDRixDQUdFLHNCQUNFLDJCQUNGLENBR0YsVUFDRSxjQUNGIiwiZmlsZSI6InNyYy9mZWF0dXJlcy9GbGF0Qm9va2luZ0ZlYXR1cmUvc3RlcHMvRmxhdEJvb2tpbmdQaG9uZVN0ZXAvRmxhdEJvb2tpbmdQaG9uZVN0ZXAuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiLmNvbXBvbmVudCB7XG4gIG1hcmdpbi10b3A6IDE2cHg7XG59XG5cbi5maWVsZCB7XG4gIDpnbG9iYWwobGFiZWwpIHtcbiAgICBtYXJnaW4tYm90dG9tOiA5cHggIWltcG9ydGFudDtcbiAgfVxufVxuXG4uY29udHJvbHMge1xuICBtYXJnaW4tdG9wOiA5cHg7XG59XG4iXX0= */</style>

<svelte:window bind:innerWidth={windowWidth} />

<form on:submit={onSubmit} class="component" novalidate>
  <div class="field">
    <InputPhone
      label="Номер телефона"
      theme="regular"
      note=""
      rules={['required', 'completed']}
      bind:this={input}
      bind:valid
      errorMessage={error}
      showErrorMessage={!!error}
      validateOnInput />
  </div>

  <div class="controls">
    {#if windowWidth >= Grid.VIEWPORT_THRESHOLD_SM && windowWidth <= Grid.VIEWPORT_THRESHOLD_MD}
      <Button type="submit" {loading} block disabled={!valid} size="m">Отправить смс с кодом</Button>
    {:else}
      <Button type="submit" {loading} block disabled={!valid}>Отправить смс с кодом</Button>
    {/if}
  </div>
</form>
